@media only screen and (max-width: 600px) {
    .bigblue {
        background-color: lightblue;
        display: none;
    }
}

@media only screen and (min-width: 600px) {
    .bigblue {
        background-color: red;
        display: none;
    }
}

.dropdown-toggle:after {
    content: none
}

nav {
    background-color: "red"
}