/* Inactive tab */
/* .myClass .nav-tabs .nav-item.show .nav-link,
.myClass .nav-tabs .nav-link.active {
    color: orangered;
    background-color: #fff;
    border-color: none;
}

.myClass .nav-tabs {
    border-bottom: none;
}

.myClass .nav-tabs .nav-link:hover {
    color: blue
} */
.myClass {
    background-color: red;
}