.inputreadonlystyle {
    border-radius: 0;
    background-color: white;

}

InputGroup {
    width: 90% !important;
}

.multiselect {
    width: 80% !important;
}

fieldset.scheduler-border {
    border: 1px groove #ddd !important;
    padding: 0 1.4em 1.4em 1.4em !important;
    margin: 0 0 1.5em 0 !important;
    -webkit-box-shadow: 0px 0px 0px 0px #000;
    box-shadow: 0px 0px 0px 0px #000;
}

legend.scheduler-border {
    font-size: 1.2em !important;
    font-weight: bold !important;
    text-align: left !important;
    width: auto;
    padding: 0 10px;
    border-bottom: none;
}

.flexRowDirection {
    display: flex;
    flex-direction: row;
}

thead {
    background-color: whitesmoke;
}

tr th {

    display: table-cell;
    align-content: center;

    padding-left: 50px;


    border: #000000 solid 1px;
    margin: 5px;

}

.nav-tabs .nav-link {
    margin-bottom: -1px;
    border: 1px solid orange;
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem;
}

.dropdown-toggle:after {
    content: none
}